<template>
  <section>
    <b-card class="mb-0">
      <h2>{{ $t('service.technical_informations.title') }}</h2>
      <p>{{ $t(`service.technical_informations.introduction_${product.typology}`) }}</p>
    </b-card>

    <validation-observer ref="technicalInformationFormRef" tag="form" @submit.prevent="onSubmit">
      <b-card class="mb-0">
        <!-- HANGAR -->
        <b-row v-if="product.typology === 'hangar'" class="mt-2">
          <!-- NET MARKETED FLOOR AREA -->
          <b-col cols="12">
            <app-input
              id="NetMarketedFloorArea"
              v-model.number="product.mainVariant.technical.floorArea"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.net_marketed_floor_area')"
              :append="$getMeasurementUnitItn('m2')"
              rules="required"
              type="number"
              step="0.01"
              :overlay="!isLoaded"
            />
          </b-col>

          <!-- HEIGHT DOOR MAX -->
          <b-col cols="12" md="6">
            <app-input
              id="heightDoorMax"
              v-model.number="product.mainVariant.technical.maxDoorHeight"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.height_door_max')"
              :append="$getMeasurementUnitItn('m')"
              rules="required"
              type="number"
              step="0.01"
              :overlay="!isLoaded"
            />
          </b-col>

          <!-- WIDTH DOOR MAX -->
          <b-col cols="12" md="6">
            <app-input
              id="widthDoorMax"
              v-model.number="product.mainVariant.technical.maxDoorWidth"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.width_door_max')"
              :append="$getMeasurementUnitItn('m')"
              rules="required"
              type="number"
              step="0.01"
              :overlay="!isLoaded"
            />
          </b-col>
        </b-row>

        <!-- PARKING | OUTSIDE_RAMPS -->
        <b-row v-else>
          <b-col cols="12">
            <!-- PARKING AREA -->
            <app-input
              id="outsideRampsArea"
              v-model.number="product.mainVariant.technical.parkingArea"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.parking_area')"
              :append="$getMeasurementUnitItn('m2')"
              rules="required"
              type="number"
              step="any"
              :overlay="!isLoaded"
            />
          </b-col>

          <!-- WINGSPAN MIN -->
          <b-col cols="12" md="6">
            <app-input
              id="wingspanMin"
              v-model.number="product.mainVariant.technical.minWingspan"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.wingspan_min')"
              vv-name
              :append="$getMeasurementUnitItn('m')"
              rules="max_value:@wingspanMax"
              type="number"
              step="any"
              :overlay="!isLoaded"
            />
          </b-col>

          <!-- WINGSPAN MAX -->
          <b-col cols="12" md="6">
            <app-input
              id="wingspanMax"
              v-model.number="product.mainVariant.technical.maxWingspan"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.wingspan_max')"
              :append="$getMeasurementUnitItn('m')"
              rules="min_value:@wingspanMin"
              type="number"
              step="any"
              :overlay="!isLoaded"
              vv-name
            />
          </b-col>
        </b-row>

        <b-row>
          <!-- MTOW MIN -->
          <b-col cols="12" md="6">
            <app-input
              id="mtowMin"
              v-model.number="product.mainVariant.technical.minMtow"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.mtow_min')"
              :append="$getMeasurementUnitItn('kg')"
              :rules="`positive${product.mainVariant.technical.maxMtow !== null ? '|max_value:@mtowMax' : ''}`"
              type="number"
              step="any"
              :overlay="!isLoaded"
              vv-name
            />
          </b-col>

          <!-- MTOW MAX -->
          <b-col cols="12" md="6">
            <app-input
              id="mtowMax"
              v-model.number="product.mainVariant.technical.maxMtow"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.mtow_max')"
              :append="$getMeasurementUnitItn('kg')"
              rules="positive|min_value:@mtowMin"
              type="number"
              step="any"
              :overlay="!isLoaded"
              vv-name
            />
          </b-col>

          <!-- DURATION MIN -->
          <b-col cols="12" md="6">
            <app-input
              id="durationMin"
              v-model.number="product.mainVariant.technical.minOccupancyDays"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.duration_min')"
              :append="$t('common.day')"
              :rules="`positive${product.mainVariant.technical.maxOccupancyDays !== null ? '|max_value:@durationMax' : ''}`"
              type="number"
              :overlay="!isLoaded"
              vv-name
            />
          </b-col>

          <!-- DURATION MAX -->
          <b-col cols="12" md="6">
            <app-input
              id="durationMax"
              v-model.number="product.mainVariant.technical.maxOccupancyDays"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :label="$t('service.technical_informations.form.duration_max')"
              :append="$t('common.day')"
              rules="positive|min_value:@durationMin"
              type="number"
              :overlay="!isLoaded"
              vv-name
            />
          </b-col>
        </b-row>
      </b-card>

      <!-- PARKING -->
      <template v-if="product.typology !== 'parking'">
        <b-form-checkbox v-model="product.mainVariant.technical.setup" :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'" class="mx-2 mt-3" name="check-button" switch inline>
          {{ $t('service.technical_informations.setting_slices') }}
        </b-form-checkbox>

        <p class="m-2">{{ $t('common.notice') }} {{ $t('service.technical_informations.notice_slices') }}</p>

        <app-data-table
          v-if="product.mainVariant.technical.setup"
          ref="sliceTable"
          :fields="fields"
          :items="product._reference.mainVariant.technical.slices"
          :per-page="product._reference.mainVariant.technical.slices.length"
          :small="$store.getters['app/mdAndDown']"
          :busy="!isLoaded"
        >
          <template #cell(technicalSlice)="{ item }">{{ item.sliceNumber }}</template>
          <template #cell(aircraftGround)="{ item }">
            {{ $t('common.from') }} {{ item.footPrintScaleFrom }} {{ $getMeasurementUnitItn('m2') }}
            {{ $t('common.to') }} {{ item.footPrintScaleTo }} {{ $getMeasurementUnitItn('m2') }}
          </template>
          <template #cell(icaoModelName)="{ item }">{{ item.icao }} - {{ item.name }}</template>
          <template #cell(stockMax)="{ item }">{{ item.maxAircraftPlaces }}</template>
          <template v-if="$route.name !== 'productShow' && $route.name !== 'operatorProductShow'" #cell(configure)="{ item }">
            <div class="text-center">
              <feather-icon
                :id="`${item.id}-data-table-action-configure`"
                icon="SettingsIcon"
                color="#e1ca93"
                style="cursor: pointer; width: 40px"
                size="16"
                @click="configureSlice(item.sliceNumber)"
              />
            </div>
          </template>
        </app-data-table>

        <b-card class="mb-0">
          <app-sidebar-form
            form-name="technical-slice"
            :edition-mode-title="sliceMode"
            :edition="!!sliceMode"
            :disabled="slicesLoader"
            :title="`${$t('service.technical_informations.slice')} ${selectedSlice && selectedSlice.sliceNumber}`"
            :entity="selectedSlice"
            @update:edition="
              editionMode => {
                if (!editionMode) {
                  resetMaxPlaces()
                  sliceMode = null
                }
              }
            "
            @update:entity="onSubmitSlice()"
            @reset="resetMaxPlaces()"
          >
            <template v-if="selectedSlice" #fields>
              <b-overlay :show="selectedSliceLoader" rounded spinner-small spinner-variant="primary">
                <div class="mb-3">
                  <p>{{ $t('service.technical_informations.ref_model') }} : {{ selectedSlice.icao }} - {{ selectedSlice.name }}</p>
                  <p>{{ $t('service.technical_informations.length') }} : {{ selectedSlice.length }} {{ $getMeasurementUnitItn('m') }}</p>
                  <p>{{ $t('service.technical_informations.wingspan') }} : {{ selectedSlice.wingspan }} {{ $getMeasurementUnitItn('m') }}</p>
                  <p>{{ $t('service.technical_informations.tail_height') }} : {{ selectedSlice.tailHeight }} {{ $getMeasurementUnitItn('m') }}</p>
                  <p>{{ $t('service.technical_informations.floor_space') }} : {{ selectedSlice.floorSpace }} {{ $getMeasurementUnitItn('m2') }}</p>
                </div>
                <div class="mb-2">
                  <p>
                    {{ $t('service.technical_informations.hangar_theorical_stack') }}
                    {{ setTheoricalStack() }}
                  </p>
                  <app-input
                    v-model.number="selectedSlice.maxAircraftPlaces"
                    :label="$t('service.technical_informations.max_place_slice')"
                    type="number"
                    :rules="rulesMaxPlaces()"
                    autofocus
                  />
                </div>
                <p>{{ $t('common.notice') }} {{ $t('service.technical_informations.notice_place') }}</p>
              </b-overlay>
            </template>
          </app-sidebar-form>
        </b-card>
      </template>

      <b-card v-else class="mb-0">
        <b-form-checkbox v-model="product.mainVariant.technical.setup" class="mb-2" name="check-button" switch inline>
          {{ $t('service.technical_informations.setting_parking_places') }}
        </b-form-checkbox>

        <template v-if="product.mainVariant.technical.setup">
          <validation-provider #default="{ errors }" :name="$t('service.technical_informations.management')" rules="required" class="validation-required">
            <p v-if="!product.mainVariant.technical.canSliceBeEdited && initialManagementType === 'classic'" class="text-danger">{{ $t('service.technical_informations.existing_custom_events') }}</p>

            <b-form-group label-for="managementType" :label="$t('service.technical_informations.management')">
              <v-select
                v-model="product.mainVariant.technical.managementType"
                input-id="managementType"
                :options="managementTypes"
                :reduce="val => val.value"
                :clearable="false"
                :disabled="!product.mainVariant.technical.canSliceBeEdited && initialManagementType === 'classic'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-card no-body class="border px-2">
            <p class="my-2">{{ $t('common.notice') }} {{ $t('service.technical_informations.notice_parking_places') }}</p>

            <p v-if="!product.mainVariant.technical.canSliceBeEdited && product.mainVariant.technical.managementType === 'slice'" class="text-danger">{{ $t('service.technical_informations.existing_custom_events') }}</p>

            <app-input
              v-if="product.mainVariant.technical.managementType === 'classic'"
              id="maxPlaces"
              v-model.number="product.mainVariant.technical.maxSpots"
              :label="$t('service.technical_informations.parking_places')"
              type="number"
              required
            />

            <template v-else>
              <b-row class="mb-2">
                <b-col cols="12" md="3">
                  <enum-select
                    id="measurement"
                    v-model="product.mainVariant.technical.sliceUnit"
                    :label="$t('service.prices.type_measurement')"
                    enum-class="ParkingSliceUnit"
                    :disabled="!product.mainVariant.technical.canSliceBeEdited"
                    :clearable="false"
                  />
                </b-col>
              </b-row>

              <b-row v-for="(slice, index) in product.mainVariant.technical.slices" :key="index" class="mb-3 mb-md-0">
                <b-col cols="12" md="">
                  <app-quantity-per-unit-input
                    :id="`fromQuantity${index + 1}`"
                    v-model.number="slice.fromQuantity"
                    :units-type="product.mainVariant.technical.sliceUnit"
                    :label="upperFirst($t('common.from'))"
                    :vv-name="`'${upperFirst($t('common.from'))} [${lowerCase($t('service.prices.slice'))}: ${index + 1}]'`"
                    type="number"
                    step="any"
                  />
                </b-col>
                <b-col cols="12" md="">
                  <app-quantity-per-unit-input
                    :id="`toQuantity${index + 1}`"
                    v-model.number="slice.toQuantity"
                    :units-type="product.mainVariant.technical.sliceUnit"
                    :label="`${upperFirst($t('common.to'))}`"
                    :vv-name="`'${upperFirst($t('common.to'))} [${lowerCase($t('service.prices.slice'))}: ${index + 1}]'`"
                    type="number"
                    step="any"
                  />
                </b-col>
                <b-col cols="12" md="">
                  <app-input
                    :id="`sliceMaxPlaces${index}`"
                    v-model.number="slice.availableSpots"
                    :label="$t('service.technical_informations.slice_parking_places')"
                    rules="required|positive"
                    type="number"
                  />
                </b-col>

                <b-col cols="12" md="2">
                  <b-button-group class="pt-md-2">
                    <b-button
                      v-if="product.mainVariant.technical.slices.length > 1"
                      v-ripple.400
                      size="md"
                      variant="outline-primary"
                      @click="modalDeleteItem(index)"
                    >
                      <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="minus" class="text-danger" :title="$t('action.delete')" />
                    </b-button>
                    <b-button
                      v-if="index === product.mainVariant.technical.slices.length - 1"
                      v-ripple.400
                      size="md"
                      variant="outline-primary"
                      @click="repeatAgain"
                    >
                      <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="plus" class="text-success" :title="$t('action.add')" />
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </template>
      </b-card>

      <footer-form-service
        v-if="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
        class="mb-2"
        previous
        next
        :disabled="saveLoader"
        @click:previous="$emit('previous-tab')"
        @click:next="$emit('next-tab')"
      />
      <footer-form-service
        v-else
        class="mb-2"
        previous
        save
        save-next
        :disabled="saveLoader"
        @click:previous="$emit('previous-tab')"
        @click:save-next="nextRedirection = true"
      />
    </validation-observer>
  </section>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'
import { isNumber, lowerCase, pick, upperFirst } from 'lodash'

import AppDataTable from '@/components/AppDataTable.vue'
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppQuantityPerUnitInput from '@/components/AppQuantityPerUnitInput.vue'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  name: 'TechnicalInformation',

  components: {
    AppDataTable,
    AppSidebarForm,
    FooterFormService,
    AppQuantityPerUnitInput,
  },

  setup(_props, ctx) {
    const { $i18n, toaster, _cloneDeep, $bvModal, alert } = ctx.root
    const $emit = ctx.emit

    const isLoaded = ref(false)
    const initialManagementType = ref(null)

    const product = inject('product')
    product.value.fetchTechnical(ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(() => {
      isLoaded.value = true
      initialManagementType.value = product.value.mainVariant.technical.managementType
      if (!product.value.mainVariant.technical.slices.length) {
        product.value.mainVariant.technical.slices.push({
          id: null,
          sliceUnit: 'mtow',
          fromQuantity: 0,
          toQuantity: null,
          availableSpots: 0,
        })
      }
    })

    const technicalInformationFormRef = ref(null)
    const saveLoader = ref(false)
    const nextRedirection = ref(false)

    const fields = [
      { key: 'technicalSlice', label: $i18n.t('service.technical_informations.technical_slice') },
      { key: 'aircraftGround', label: $i18n.t('service.technical_informations.aircraft_ground') },
      { key: 'icaoModelName', label: $i18n.t('service.technical_informations.icao_model_name') },
      { key: 'stockMax', label: $i18n.t('service.technical_informations.stock_max') },
      { key: 'configure', label: $i18n.t('common.configure') },
    ]

    const onSubmit = () => technicalInformationFormRef.value.validate().then(validForm => {
      if (validForm) {
        saveLoader.value = true
        product.value
          .patchTechnical()
          .then(() => {
            initialManagementType.value = product.value.mainVariant.technical.managementType
            if (nextRedirection.value) {
              $emit('next-tab')
              nextRedirection.value = false
            }
          })
          .finally(() => {
            saveLoader.value = false
          })
      } else {
        toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      }
    })

    // SLICES

    const slicesEnabled = ref(true)
    const slicesLoader = ref(false)
    const selectedSliceLoader = ref(false)
    const selectedSlice = ref(null)
    const sliceMode = ref(null)

    const resetMaxPlaces = () => {
      selectedSlice.value.maxAircraftPlaces = product.value._reference.mainVariant.technical.slices[selectedSlice.value.sliceNumber - 1].maxAircraftPlaces
    }

    const rulesMaxPlaces = () => {
      if (!selectedSlice.value) return undefined
      const rules = ['required', 'positive', 'integer']

      if (selectedSlice.value.sliceNumber > 1) {
        rules.push(`${`max_value:${product.value.mainVariant.technical.slices[selectedSlice.value.sliceNumber - 2].maxAircraftPlaces}`}`)
      }
      if (selectedSlice.value.sliceNumber > product.value.mainVariant.technical.slices.length) {
        rules.push(`${`min_value:${product.value.mainVariant.technical.slices[selectedSlice.value.sliceNumber].maxAircraftPlaces}`}`)
      }
      return rules.join('|')
    }

    const configureSlice = sliceNumber => {
      sliceMode.value = 'edit'
      selectedSlice.value = product.value.mainVariant.technical.slices.find(slice => slice.sliceNumber === sliceNumber)

      if (Object.hasOwnProperty.call(selectedSlice.value, 'theoreticalFootPrint')) return

      selectedSliceLoader.value = true

      product.value.fetchSlice(selectedSlice.value.id).then(slice => {
        selectedSlice.value = slice
        selectedSliceLoader.value = false
      })
    }

    const setTheoricalStack = () => {
      const area = product.value.typology === 'hangar' ? 'floorArea' : 'parkingArea'
      return Math.floor(product.value.mainVariant.technical[area] / selectedSlice.value.floorSpace)
    }

    const onSubmitSlice = () => {
      slicesLoader.value = true
      product.value.patchSlice(pick(selectedSlice.value, 'id', 'maxAircraftPlaces')).finally(() => {
        slicesLoader.value = false
      })
    }

    // PARKING PLACES

    const parkingPlacesEnabled = ref(true)
    const managementTypes = [
      { value: 'classic', label: $i18n.t('service.technical_informations.management_classic') },
      { value: 'slice', label: $i18n.t('service.technical_informations.management_slice') },
    ]

    const rulesFromQuantity = (slice, index) => {
      const rules = ['positive', 'required']
      const vidToQuantityPreviousSlice = `toQuantity${index}`
      const vidToQuantityCurrentSlice = `toQuantity${index + 1}`

      if (index) rules.push(`min_value:@${vidToQuantityPreviousSlice}`)
      if (slice.toQuantity !== null) rules.push(`max_value:@${vidToQuantityCurrentSlice}`)
      return rules.join('|')
    }

    const rulesToQuantity = (slice, index) => {
      const rules = ['positive']
      const vidFromQuantityCurrentSlice = `fromQuantity${index + 1}`
      const vidFromQuantityNextSlice = `fromQuantity${index + 2}`

      if (slice.toQuantity !== null) rules.push(`min_value:@${vidFromQuantityCurrentSlice}`)
      if (index + 1 !== product.value.mainVariant.technical.slices.length) {
        rules.push(`max_value:@${vidFromQuantityNextSlice}`, 'required')
      }
      return rules.join('|')
    }

    const repeatAgain = () => {
      const newSlicePrice = _cloneDeep(product.value.mainVariant.technical.slices[product.value.mainVariant.technical.slices.length - 1])
      newSlicePrice.id = null
      newSlicePrice.fromQuantity = newSlicePrice.toQuantity + 1
      newSlicePrice.toQuantity += product.value.mainVariant.technical.slices[0].toQuantity
      product.value.mainVariant.technical.slices.push(newSlicePrice)
    }

    const modalDeleteItem = index => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            alert($i18n.t('alert.delete.success'))
            product.value.mainVariant.technical.slices.splice(index, 1)
          }
        })
    }

    return {
      product,
      isLoaded,
      technicalInformationFormRef,
      nextRedirection,
      fields,
      saveLoader,
      setTheoricalStack,
      onSubmit,
      slicesLoader,
      selectedSliceLoader,
      slicesEnabled,
      sliceMode,
      selectedSlice,
      configureSlice,
      onSubmitSlice,
      parkingPlacesEnabled,
      managementTypes,
      repeatAgain,
      modalDeleteItem,
      isNumber,
      upperFirst,
      lowerCase,
      resetMaxPlaces,
      rulesMaxPlaces,
      rulesFromQuantity,
      rulesToQuantity,
      initialManagementType,
    }
  },
})
</script>
